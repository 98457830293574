import { week as defaultNFLWeek, season as defaultNFLSeason } from "./nfl-week"
import { defaultDate as defaultNBADate } from "../containers/nba/util/nba-date"
import moment from 'moment-timezone'

const EST_TIME_CUTOFF_PGA = 18

export function calcESTDay() {
  const d = new Date()
  const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
  const nd = new Date(utc + (3600000*-5)) // Timezone EST is -5 (or not depending on daylight savings but close enough)
  return nd
}

export const getToday = () => {
  // return '2023-01-13'
  const timeInEST = moment().tz('America/Los_Angeles').format('YYYY-MM-DD')

  return timeInEST
  // const offset = d.getTimezoneOffset()
  // console.log(new Date(d.getTime() - (offset*60*1000)))
  // return d.toISOString().split('T')[0]
}

// For PGA Tournaments run from Thurs - Mon (generally)
// Tuesday or Wed default to Thurs
export const getGolfDay = () => {
  let timeInEST = moment().tz('America/New_York')
  if (timeInEST.hour() >= EST_TIME_CUTOFF_PGA) {
    timeInEST.add(1, 'days')
  }
  // Sun = 0, Thur = 4
  const dayOfWeek = timeInEST.day()
  // If Tuesday or Wed go to Thurs
  if (dayOfWeek > 1 && dayOfWeek < 4) {
    timeInEST = timeInEST.add(4-dayOfWeek, 'days')
  }
  return timeInEST.format('YYYY-MM-DD')
}

// return [counter, season]
const getDefaultDateInfoForSport = (sport) => {
  switch (sport) {
    case 'nfl':
      return [defaultNFLWeek, defaultNFLSeason]
    case 'nba':
      return [getToday(), 2023]
    case 'mlb':
      return [getToday(), 2023]
    case 'pga':
      return [getGolfDay(), 2024]
    default:
      return [undefined, undefined]
  }
}

export default getDefaultDateInfoForSport