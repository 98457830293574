import { Spin } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { checkPartnerToken } from '../actions/auth'
import { TAG_KEY } from '../constants/local-storage-keys'
import { calcESTDay, getDayKeyFromDate } from '../sagas/lineup'
import { api_token, api_id } from '../constants/partner'

class Initializer extends Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    let user_id = params.get('id')

    this.props.checkPartnerToken(token, user_id)
    // Set up local tag storage on init
    const dateKey = getDayKeyFromDate(calcESTDay())
    const savedTags = JSON.parse(localStorage.getItem(TAG_KEY))
    if (!savedTags || savedTags.date !== dateKey) localStorage.setItem(TAG_KEY, JSON.stringify({
      date: dateKey
    }))
  }

  render() {
    if (this.props.auth.loadingCheck) {
      return (
        <div style={{marginTop: '400px', textAlign: 'center'}}>
          <Spin />
        </div>
      )

    }
    return (
      <div>{ this.props.children }</div>
    )
  }
}

export default connect(
  state => ({
    auth: state.auth
  }),
  {
    checkPartnerToken: checkPartnerToken
  }
)(Initializer)
