export const framed = true
export const partner = 'betsperts'
export const overridePerms = true
export const authed = false
export const customPresets = false

// CSS Overrides
export const overridePrimary = '#2ce584'
export const overrideSecondary = '#999'
export const overrideCheckboxColor = '#0079f0'
// TEMP remove
export const api_token = ''
export const api_id = ''

export const allowCheatsheets = true